<template>
  <div>
    <app-run-list-header
      v-if="!isArchiveMode"
      @select="updateSearch" />
    <app-archive-list-header
      v-else
      :display-loader="displayLoader"
      @onSearch="onArchiveSearch" />
    <app-confirmation-modal
      ref="deleteArchiveConfirmModal"
      :title="$t('runInterpretation.archive.delete.permanentDeleteActionLabel')"
      :message="$t('runInterpretation.archive.delete.permanentDeleteArchiveConfirmMessage')"
      :ok-button-name="$t('common.yes')"
      :ok-button-classes="['run-deletion-modal-ok-button']"
      ok-button-prevent-hiding
      ok-button-spinners-enabled
      ok-button-variant="outline-danger"
      :cancel-button-name="$t('cancel')"
      header-text-variant="danger"
      @ok="permanentDeleteArchive()" />
    <app-confirmation-modal
      ref="restoreArchiveConfirmModal"
      :title="$t('runInterpretation.archive.restore.actionLabel')"
      :message="$t('runInterpretation.archive.restore.archiveConfirmMessage')"
      :ok-button-name="$t('common.yes')"
      :ok-button-classes="['run-deletion-modal-ok-button']"
      ok-button-prevent-hiding
      ok-button-spinners-enabled
      ok-button-variant="outline-danger"
      :cancel-button-name="$t('cancel')"
      header-text-variant="danger"
      @ok="restoreArchive()" />
    <div class="text-center">
      <b-table
        responsive
        striped
        hover
        show-empty
        thead-class="run-table-header"
        :busy="displayLoader"
        :fields="fields"
        :items="runs"
        :empty-filtered-text="$t('noDataFound')"
        :empty-text="$t('noDataFound')"
        :no-local-sorting="true"
        sort-by="businessId"
        :sort-desc="true"
        @sort-changed="reloadSort">
        <template #cell(businessId)="data">
          <template v-if="data.item.deletionDate">
            <font-awesome-icon
              :id="`deletionFlag-${data.index}`"
              :icon="['far', 'clock']"
              class="mr-1 text-danger" />
            <b-popover
              :target="`deletionFlag-${data.index}`"
              placement="top"
              triggers="hover"
              :content="$t('runInterpretation.archive.deletionFlagTooltip')" />
          </template>
          <span>{{ data.value }}</span>
        </template>
        <template #cell(fileName)="data">
          <template v-if="allowFileRunDownload && hasRole('SUPER_ADMIN')">
            <b-button
              :id="`filename-${data.index}`"
              :ref="`filename${data.index}`"
              variant="link"
              class="overflow-dot overflow-dot-xl"
              @click="downloadFileRuns(data.item.id)">
              {{ data.value }}
            </b-button>
          </template>
          <template v-else>
            <span
              :id="`filename-${data.index}`"
              class="overflow-dot overflow-dot-xl">
              {{ data.value }}
            </span>
          </template>
          <b-popover
            v-show="textOverflow(data.value, 'filename', data.index)"
            :ref="`popoverfilename${data.index}`"
            :target="`filename-${data.index}`"
            placement="top"
            triggers="hover"
            :content="`${data.value}`" />
        </template>
        <template #cell(kitReactionBufferPairs)="data">
          <span
            :id="`krb-${data.index}`"
            :ref="`krb${data.index}`"
            class="overflow-dot overflow-dot-xl">
            {{ data.value }}
          </span>
          <b-popover
            v-show="textOverflow(data.value, 'krb', data.index)"
            :ref="`popoverkrb${data.index}`"
            :target="`krb-${data.index}`"
            placement="top"
            triggers="hover focus"
            :content="`${data.value}`" />
        </template>
        <template #cell(interpretationRunByName)="data">
          <span
            :id="`irbn-${data.index}`"
            :ref="`irbn${data.index}`"
            class="overflow-dot overflow-dot-m">
            {{ data.value }}
          </span>
          <b-popover
            v-show="textOverflow(data.value, 'irbn', data.index)"
            :ref="`popoverirbn${data.index}`"
            :target="`irbn-${data.index}`"
            placement="top"
            triggers="hover focus"
            :content="`${data.value}`" />
        </template>
        <template #cell(status)="data">
          <div class="d-flex justify-content-center align-items-center">
            <span
              :id="`status-${data.index}`"
              :ref="`status${data.index}`"
              class="overflow-dot overflow-dot-m">
              {{ data.value }}
            </span>
            <b-popover
              v-show="textOverflow(data.value, 'status', data.index)"
              :ref="`popoverstatus${data.index}`"
              :target="`status-${data.index}`"
              placement="top"
              triggers="hover focus"
              :content="`${data.value}`" />
            <template v-if="data.item.ftpSent !== null">
              <span
                :id="`ftp-${data.index}`"
                :ref="`ftp${data.index}`"
                :class="data.item.ftpSent ? 'envelopeOk' : 'envelopeKo'">
                <font-awesome-icon
                  :icon="['far', 'envelope']"
                  class="ml-1" />
                <b-popover
                  :ref="`popoverftp${data.index}`"
                  :target="`ftp-${data.index}`"
                  placement="top"
                  triggers="hover focus"
                  :content="$t(`tooltip.envelope.${data.item.ftpSent ? 'ok' : 'ko'}`)" />
              </span>
            </template>
          </div>
        </template>
        <template #cell(edit)="data">
          <b-button
            v-if="data.item.interpretationRunByName"
            variant="outline"
            class="btn-icon"
            @click="checkRunParsed(data.item.id)">
            <b-row>
              <font-awesome-icon :icon="['fas', 'eye']" />
            </b-row>
          </b-button>
          <app-delete-button
            v-else
            :icon="true"
            :run-id="data.item.id"
            :can-delete="!data.item.parsed"
            @runDelete="pageChange(currentPage)" />

          <template v-if="isArchiveMode">
            <b-button
              :id="`delete-archive-btn-${data.item.id}`"
              variant="outline"
              class="btn-icon"
              :disabled="!hasRole('DELETE_PERMANENT_ARCHIVE')"
              @click="showPermanentDeleteArchive(data.item)">
              <b-row>
                <font-awesome-icon :icon="['fas', 'trash']" />
              </b-row>
            </b-button>
            <b-popover
              :target="`delete-archive-btn-${data.item.id}`"
              placement="top"
              triggers="hover"
              :content="$t('runInterpretation.archive.delete.permanentDeleteActionLabel')" />

            <b-button
              :id="`restore-archive-btn-${data.item.id}`"
              variant="outline"
              class="btn-icon"
              @click="showRestoreArchive(data.item)">
              <b-row>
                <font-awesome-icon :icon="['fas', 'undo']" />
              </b-row>
            </b-button>
            <b-popover
              :target="`restore-archive-btn-${data.item.id}`"
              placement="top"
              triggers="hover"
              :content="$t('runInterpretation.archive.restore.actionLabel')" />
          </template>
        </template>
      </b-table>
      <b-pagination
        v-if="runs.length"
        v-model="currentPage"
        align="center"
        :total-rows="runCount"
        :per-page="runPerPage"
        @change="pageChange" />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import RunListHeader from './RunListHeader';
import ArchiveListHeader from '../archive/ArchiveListHeader';
import { checkRunParsed, downloadFileRuns, getPublicProperties } from '@/service/GenefoxService';
import NotificationMixin from '../../mixins/NotificationMixin';
import SecurityMixin from '../../mixins/SecurityMixin';
import DeleteButton from '../common/DeleteButton.vue';
import ConfirmationModal from '@/components/common/ConfirmationModal';

export default {
  name: 'RunList',
  components: {
    'app-run-list-header': RunListHeader,
    'app-archive-list-header': ArchiveListHeader,
    'app-delete-button': DeleteButton,
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    SecurityMixin,
    NotificationMixin
  ],
  props: {
    isArchiveMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: undefined,
      currentPage: 1,
      sort: 'businessId',
      sortDirection: 'DESC',
      allowFileRunDownload: false,
      runId: undefined,
      displayLoader: false,
      permanentDeleteArchiveRunId: undefined,
      restoreRun: undefined
    };
  },
  computed: {
    ...mapFields('runList', [
      'runs',
      'runCount',
      'runPerPage'
    ]),
    fields() {
      return  [
        {
          key: 'businessId',
          label: this.$options.filters.capitalize(this.$t('listRunId')),
          sortable: true
        },
        {
          key: 'fileName',
          label: this.$options.filters.capitalize(this.$t('listeFileName')),
          sortable: true
        },
        {
          key: 'instrumentName',
          label: this.$options.filters.capitalize(this.$t('listInstrumentName')),
          sortable: true
        },
        {
          key: 'kitReactionBufferPairs',
          label: this.$options.filters.capitalize(this.$t('listKitReactionBufferPairs')),
          sortable: true,
          formatter: 'kitNameFormatter'
        },
        {
          key: 'interpretationRunByName',
          label: this.$options.filters.capitalize(this.$t('listTechnician')),
          sortable: true
        },
        {
          key: 'created',
          label: this.$options.filters.capitalize(this.$t('listCreatedDate')),
          sortable: true,
          formatter: 'createdDateFormatter'
        },
        {
          key: 'status',
          label: this.$options.filters.capitalize(this.$t('listRunStatus')),
          sortable: true,
          formatter: 'statusFormatter'
        },
        {
          key: 'edit',
          label: this.$options.filters.capitalize(this.$t('actions')),
          thClass: 'pl-1 pr-1'
        }
      ];
    }
  },
  mounted() {
    this.pageChange(1);

    return getPublicProperties()
      .then(properties => {
        this.allowFileRunDownload = properties.genefox.run.allowFileDownload;

        return properties;
      });
  },
  methods: {
    reloadSort(ctx) {
      this.sort = ctx.sortBy;
      this.sortDirection = ctx.sortDesc ? 'DESC' : 'ASC';
      this.pageChange(1);
    },
    createdDateFormatter(value) {
      return value ? this.$d(new Date(value)) : '';
    },
    statusFormatter(value) {
      return value ? this.$options.filters.capitalize(this.$t(value)) : '';
    },
    kitNameFormatter(value, key, run) {
      return run.usedKitReactionBufferPairs.map(pair => {
        const kitNameParts = pair.kitVersion.kit.name.split('-');
        const kit = kitNameParts[1] ? kitNameParts[1] : pair.kitVersion.kit.name;

        if (kit) {
          return `${kit.replace(/"/g, '')}${pair.reactionBuffer.name ? ` (${pair.reactionBuffer.name})` : ''}`;
        }
        else {
          return '';
        }
      }).join(', ');
    },
    updateSearch(search) {
      if (search.length >= 1) {
        this.search = search;
      } else {
        this.search = undefined;
      }
      this.pageChange(1);
    },
    onArchiveSearch() {
      this.pageChange(1);
    },
    checkRunParsed(id) {
      if (!this.isArchiveMode) {
        checkRunParsed(id)
          .then(() => this.$router.push({ name: 'run-interpretation', params: { id } }))
          .catch(error => this.showErrorNotification(this.$t('errorTitleCannotAccessRunPage'), error));
      } else {
        this.$router.push({ name: 'archive-interpretation', params: { id } });
      }

    },
    pageChange(page) {
      this.displayLoader = true;
      if (page === 1) {
        this.setRunPerPage();
      }
      const search = this.search;
      const sort = this.sort;
      const sortDirection = this.sortDirection;

      let searchPromise;
      if (this.isArchiveMode) {
        this.$store.dispatch('runList/cancel');
        searchPromise = this.$store.dispatch('archiveRunList/findArchivedRuns', { page, sort, sortDirection });
      } else {
        this.$store.dispatch('archiveRunList/cancel');
        searchPromise = this.$store.dispatch('runList/findRuns', { page, search, sort, sortDirection });
      }
      searchPromise
        .then(() => this.currentPage = page)
        .catch(error => {
          this.$store.dispatch('runList/clearRuns');
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
        })
        .finally(() => this.displayLoader = false);
    },
    setRunPerPage() {
      if (!this.$el.getElementsByClassName('run-table-header').length) {
        return;
      }
      const tableHeaderHeight = this.$el.getElementsByClassName('run-table-header')[0].clientHeight;
      const totalHeight = document.getElementsByClassName('content')[0].clientHeight - tableHeaderHeight - 56 - 38; // -56 because of navbar -38 because of paginator
      this.$store.dispatch('runList/setRunPerPage', Math.floor(totalHeight / 56.5));
    },
    textOverflow(text, ref, index) {
      const font = '16px Segoe UI';
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = font;

      return this.$nextTick(() => {
        if (!this.$refs[ref + index]) {
          return;
        }
        const overflow = context.measureText(text).width > this.$refs[ref + index].clientWidth + 5;
        if (!overflow) {
          this.$refs[`popover${ref}${index}`].$destroy();
        }
      });
    },
    downloadFileRuns(id) {
      downloadFileRuns(id)
        .then(res => {
          const FILE = window.URL.createObjectURL(new Blob([ res.data ], { type: 'application/zip' }));
          window.open(FILE);
        })
        .catch(error => this.showErrorNotification(this.$t('errorDownloadingFileRun'), error));
    },
    showPermanentDeleteArchive(run) {
      this.permanentDeleteArchiveRun = run;
      this.$refs.deleteArchiveConfirmModal.showModal();
    },
    showRestoreArchive(run) {
      this.restoreRun = run;
      this.$refs.restoreArchiveConfirmModal.showModal();
    },
    permanentDeleteArchive() {
      this.$store.dispatch('archiveRunList/permanentDeleteArchive', { runId: this.permanentDeleteArchiveRun.id })
        .then(() => {
          this.pageChange(1);
          this.showSuccessNotification(
            this.$t('runInterpretation.deleteRun.successNotification.title'),
            this.$t('runInterpretation.deleteRun.successNotification.message', [ this.permanentDeleteArchiveRun.businessId ]),
            { root: true }
          );
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
        })
        .finally(() => this.$refs.deleteArchiveConfirmModal.hideModal());
    },
    restoreArchive() {
      const sort = this.sort;
      const sortDirection = this.sortDirection;
      this.$store.dispatch('archiveRunList/restoreArchive', { runId: this.restoreRun.id })
        .then(() => {
          this.$store.dispatch('archiveRunList/findArchivedRuns', { page : 1, sort, sortDirection });
          this.showSuccessNotification(
            this.$t('runInterpretation.archive.restore.successNotification.title'),
            this.$t('runInterpretation.archive.restore.successNotification.message', [ this.restoreRun.businessId ]),
            { root: true });
        })
        .catch(error => {
          this.$bvToast.toast(error.message, {
            title: this.$options.filters.capitalize(this.$t('error')),
            variant: 'danger',
            autoHideDelay: '3000',
            toaster: 'b-toaster-bottom-left'
          });
        })
        .finally(() => this.$refs.restoreArchiveConfirmModal.hideModal());
    }
  }
};
</script>
