<template>
  <header class="header" role="banner">
    <div class="nav-wrap">
      <nav class="main-nav" role="navigation">
        <ul class="unstyled list-hover-slide">
          <li>
            <a href="#"
               @click="goToRunPage">
              <i class="fa fa-braille fa-2x"></i>
              <span class="nav-text">
                  {{ $t('app.sidebar.runButtonText') }}
              </span>
            </a>
          </li>
          <li v-if="isPhysician">
            <a href="#"
               @click="goToConfigurationPage">
              <i class="fa fa-wrench fa-2x"></i>
              <span class="nav-text">
                  {{ $t('app.sidebar.configurationButtonText') }}
              </span>
            </a>
          </li>
          <li v-if="canSeeAllLaboratories">
            <a href="#"
               @click="goToLaboratoriesPage">
              <i class="fa fa-flask fa-2x"></i>
              <span class="nav-text">
                  {{ $t('app.sidebar.laboratoriesButtonText') }}
              </span>
            </a>
          </li>
          <li v-else-if="canSeeOnlyOwnLaboratory">
            <a href="#"
               @click="goToLaboratoryPage">
              <i class="fa fa-flask fa-2x"></i>
              <span class="nav-text">
                  {{ $t('app.sidebar.laboratoryButtonText') }}
              </span>
            </a>
          </li>
          <li>
            <a href="#"
               @click="goToArchivePage">
              <i class="fa fa-folder-open fa-2x"></i>
              <span class="nav-text">
                  {{ $t('app.sidebar.archiveButtonText') }}
              </span>
            </a>
          </li>
          <li v-if="isSuperAdmin">
            <a href="#"
               @click="showConfirmationModal()">
              <i class="fa fa-refresh fa-2x"></i>
              <span class="nav-text">
                  {{ $t('app.sidebar.reindex') }}
              </span>
            </a>
          </li>
          <li>
            <a href="#"
               @click="displayLabel()">
              <i class="fa fa-question-circle fa-2x"></i>
              <span class="nav-text">
                  {{ $t('app.sidebar.aboutButtonText') }}
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>

    <app-confirmation-modal
        ref="confirmModal"
        :title="$t('modalConfirmReindexTitle')"
        :message="$t('modalConfirmReindexMessage')"
        :ok-button-name="$t('yes')"
        :cancel-button-name="$t('no')"
        @ok="reindex()"/>
    <app-about-modal
        ref="aboutModal"
        :title="$t('modalAboutTitle')"
        :ok-button-name="$t('common.ok')"/>
  </header>
</template>

<script>
import SecurityMixin from "@/mixins/SecurityMixin";
import ConfirmationModal from "@/components/common/ConfirmationModal";
import AboutModal from "@/components/common/AboutModal";
import {mapActions} from "vuex";
import NotificationMixin from "../../mixins/NotificationMixin";

export default {
  components: {
    'app-confirmation-modal': ConfirmationModal,
    'app-about-modal': AboutModal
  },
  mixins: [
    SecurityMixin,
    NotificationMixin
  ],
  computed: {
    isPhysician() {
      return this.hasRole('PHYSICIAN');
    },
    canSeeOnlyOwnLaboratory() {
      return this.hasRole('READ_OWN_LABORATORY');
    },
    canSeeAllLaboratories() {
      return this.hasRole('READ_ALL_LABORATORY');
    },
    isSuperAdmin() {
      return this.hasRole('SUPER_ADMIN');
    }
  },
  methods: {
    ...mapActions('configuration', [
      'reindexData'
    ]),
    goToRunPage() {
      this.$router.push({
        name: 'run-list'
      });
    },
    goToArchivePage() {
      this.$router.push({
        name: 'archive'
      });
    },
    goToConfigurationPage() {
      this.$router.push({
        name: 'configure'
      });
    },
    goToLaboratoriesPage() {
      this.$router.push({
        name: 'laboratories'
      });
    },
    goToLaboratoryPage() {
      this.$router.push({
        name: 'laboratory'
      });
    },
    showConfirmationModal() {
      this.$refs.confirmModal.showModal();
    },
    displayLabel() {
      this.$refs.aboutModal.showModal();
    },
    reindex() {
      this.reindexData()
          .then(() => this.showSuccessNotification(this.$t('reindexDataSuccessTitle'), this.$t('reindexDataSuccessMessage')))
          .catch(() => this.showSuccessNotification(this.$t('reindexDataErrorTitle'), this.$t('reindexDataErrorMessage')));
    }
  }
};
</script>