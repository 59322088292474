import Vue from 'vue';
import Vuex from 'vuex';
import RunImport from './modules/RunImport';
import RunList from './modules/RunList';
import Security from './modules/Security';
import ChartModule from '@/store/modules/ChartModule';
import Configuration from './modules/Configuration';
import Template from './modules/Template';
import Laboratory from "@/store/modules/Laboratory";
import RunInterpretationStore from "@/store/modules/RunInterpretationStore";
import ArchiveRunList from "@/store/modules/ArchiveRunListStore";

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  modules: {
    'runImport': RunImport,
    'runList': RunList,
    'chart': ChartModule,
    'security': Security,
    'configuration': Configuration,
    'template': Template,
    'laboratory': Laboratory,
    'runInterpretation':  RunInterpretationStore,
    'archiveRunList':  ArchiveRunList,
  }
});
