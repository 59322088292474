<template>
  <div>
    <b-form @submit.prevent="searchClicked">
      <b-form-row class="mt-2">
        <!-- Col 1 -->
        <b-col xl="3">
          <b-form-group
            :label="$t('archive.fileName')"
            :label-cols="labelCols"
            :label-align="labelAlign"
            label-size="sm">
            <b-form-input
              v-model="filename"
              size="sm" />
          </b-form-group>

          <b-form-group
            :label="$t('archive.instrument')"
            :label-cols="labelCols"
            :label-align="labelAlign"
            label-size="sm">
            <b-form-input
              v-model="instrument"
              size="sm" />
          </b-form-group>
        </b-col>

        <!-- Col 2 -->
        <b-col xl="3">
          <b-form-group
            :label="$t('archive.kit')"
            :label-cols="labelCols"
            :label-align="labelAlign"
            label-size="sm">
            <b-form-input
              v-model="kitOrRb"
              size="sm" />
          </b-form-group>

          <b-form-group
            :label="$t('archive.operator')"
            :label-cols="labelCols"
            :label-align="labelAlign"
            label-size="sm">
            <b-form-input
              v-model="operator"
              size="sm" />
          </b-form-group>
        </b-col>

        <!-- Col 3 -->
        <b-col xl="3">
          <b-form-group
            :label="$t('archive.fromDate')"
            :label-cols="labelCols"
            :label-align="labelAlign"
            label-size="sm">
            <date-picker
              v-model="runDateFrom"
              :model-config="modelConfig"
              timezone="UTC">
              <template #default="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  class="form-control form-control-sm"
                  v-on="inputEvents">
              </template>
            </date-picker>
          </b-form-group>

          <b-form-group
            :label="$t('archive.toDate')"
            :label-cols="labelCols"
            :label-align="labelAlign"
            label-size="sm">
            <date-picker
              v-model="runDateTo"
              :model-config="modelConfig"
              timezone="UTC">
              <template #default="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  class="form-control form-control-sm"
                  v-on="inputEvents">
              </template>
            </date-picker>
          </b-form-group>
        </b-col>

        <!-- Col 4 -->
        <b-col xl="3">
          <b-form-group>
            <b-form-checkbox
              v-model="exactMatch"
              name="exactMatch-button"
              switch>
              {{ $t('archive.exactMatch') }}
            </b-form-checkbox>
          </b-form-group>

          <b-button
            v-if="!displayLoader"
            type="submit"
            class="mb-3"
            variant="primary">
            <font-awesome-icon
              :icon="['fas', 'search']"
              class="mr-1" />
            {{ $t('headerSearch') | capitalize }}
          </b-button>
          <b-button
            v-else
            class="mb-3"
            disabled>
            <b-spinner
              small
              type="grow" />
            Loading...
          </b-button>

          <b-button
            v-if="!displayLoader"
            variant="outline-secondary"
            type="button"
            class="mb-3 ml-2"
            @click="clearFilters()">
            <font-awesome-icon :icon="['fas', 'filter']" />
            {{ $t('headerClearFilters') | capitalize }}
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  props : {
    displayLoader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modelConfig: {
        type: Date(),
        timeAdjust: '00:00:00'
      },
      isXlScreen: false,

      runDateFrom: null,
      runDateTo: null,
      instrument: '',
      operator: '',
      filename: '',
      kitOrRb: '',
      exactMatch: false
    };
  },
  computed: {
    ...mapFields('archiveRunList', [
      'searchCriteria'
    ]),
    labelCols() {
      return this.isXlScreen ? 4 : 12;
    },
    labelAlign() {
      return this.isXlScreen ? 'right' : 'left';
    }
  },
  watch: {
    runDateFrom(newVal) {
      if (newVal && this.runDateTo && new Date(newVal) > new Date(this.runDateTo)) {
        this.runDateTo = null;
      }
    },
    runDateTo(newVal) {
      if (newVal && this.runDateFrom && new Date(newVal) < new Date(this.runDateFrom)) {
        this.runDateFrom = null;
      }
    }
  },
  mounted() {
    this.buildSearchCriteriaFromStore();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    searchClicked() {
      this.searchCriteria = [];
      if (this.runDateFrom) {
        this.searchCriteria.push({
          field: 'created',
          operator: '>=',
          value: this.toLocalDateTimeStr(this.runDateFrom)
        });
      }
      if (this.runDateTo) {
        this.searchCriteria.push({
          field: 'created',
          operator: '<=',
          value: this.toLocalDateTimeStr(this.runDateTo)
        });
      }
      if (this.instrument) {
        this.searchCriteria.push({
          field:'instrumentName',
          operator: this.exactMatch ? '=' : 'like',
          value: this.instrument
        });
      }

      if (this.operator) {
        this.searchCriteria.push({
          field:'interpretationRunByName',
          operator: this.exactMatch ? '=' : 'like',
          value: this.operator
        });
      }

      if (this.filename) {
        this.searchCriteria.push({
          field:'fileName',
          operator: this.exactMatch ? '=' : 'like',
          value: this.filename
        });
      }

      if (this.kitOrRb) {
        this.kitOrRb = this.kitOrRb?.trim();
        this.searchCriteria.push({
          field:'kits',
          operator: 'like',
          value: this.exactMatch ? `"${this.kitOrRb}"` : this.kitOrRb
        });
      }

      this.$emit('onSearch');
    },
    toLocalDateTimeStr(date) {
      return JSON.stringify(date).replaceAll('"', '').replace(/Z$/, '');
    },
    handleResize() {
      this.isXlScreen = window.innerWidth >= 1200;
    },
    clearFilters() {
      this.runDateFrom = null;
      this.runDateTo = null;
      this.instrument = '';
      this.operator = '';
      this.filename = '';
      this.kitOrRb = '';
      this.exactMatch = false;
      this.searchClicked();
    },
    buildSearchCriteriaFromStore() {
      this.runDateFrom = this.searchCriteria.find(criteria => criteria.field === 'created' && criteria.operator === '>=')?.value;
      this.runDateTo = this.searchCriteria.find(criteria => criteria.field === 'created' && criteria.operator === '<=')?.value;
      this.instrument = this.searchCriteria.find(criteria => criteria.field === 'instrumentName')?.value;
      this.operator = this.searchCriteria.find(criteria => criteria.field === 'interpretationRunByName')?.value;
      this.filename = this.searchCriteria.find(criteria => criteria.field === 'fileName')?.value;
      this.kitOrRb = this.searchCriteria.find(criteria => criteria.field === 'kits')?.value;
    }
  }
};
</script>
