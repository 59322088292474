<template>
  <span>
    <template v-if="icon">
      <b-button
        variant="outline"
        class="btn-icon"
        @click="showRunDeletionModal()">
        <b-row>
          <font-awesome-icon :icon="['fas', 'trash']" />
        </b-row>
      </b-button>
    </template>
    <template v-else>
      <b-button
        class="p-2 bd-highlight"
        variant="outline-danger"
        :disabled="!canDelete"
        @click="showRunDeletionModal">
        {{ $t('runInterpretationFooterDeleteRunButtonText') | capitalizeAll }}
      </b-button>
    </template>
    <app-confirmation-modal
      ref="runDeletionModal"
      :title="$t('runInterpretation.deletionModal.title')"
      :message="isArchiveAndDelete ? $t('runInterpretation.archive.archiveAndDeleteConfirmMessage') : $t('runInterpretation.deletionModal.message')"
      :ok-button-name="$t('runInterpretation.deletionModal.okButtonText')"
      :ok-button-classes="['run-deletion-modal-ok-button']"
      ok-button-prevent-hiding
      ok-button-spinners-enabled
      ok-button-variant="outline-danger"
      :cancel-button-name="$t('runInterpretation.deletionModal.cancelButtonText')"
      header-text-variant="danger"
      @ok="isArchiveAndDelete ? archiveAndDeleteRun() : deleteRun()" />
  </span>
</template>

<script>
import ConfirmationModal from './ConfirmationModal.vue';
import { deleteRun } from '../../service/GenefoxService';
import NotificationMixin from '../../mixins/NotificationMixin';
import ArchiveParams from '../../models/ArchiveParams';
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    'app-confirmation-modal': ConfirmationModal
  },
  mixins: [
    NotificationMixin
  ],
  props: {
    icon: {
      type: Boolean,
      default: false
    },
    runId: {
      type: Number,
      default: undefined
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    isArchiveAndDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapFields('runImport', [
      'run'
    ])
  },
  methods: {
    showRunDeletionModal() {
      this.$refs.runDeletionModal.showModal();
    },
    deleteRun() {
      deleteRun(this.runId)
        .then(run => {
          this.showSuccessNotification(
            this.$t('runInterpretation.deleteRun.successNotification.title'),
            this.$t('runInterpretation.deleteRun.successNotification.message', [ run.businessId ]),
            { root: true });
          this.$emit('runDelete');
          this.$refs.runDeletionModal.hideModal();

        })
        .catch(error => {
          this.$refs.runDeletionModal.hideModal();
          this.showErrorNotification(this.$t('error'), error);
          console.error(`Error while deleting run: ${error}`);
        });

    },
    archiveAndDeleteRun() {
      this.$store.dispatch('runInterpretation/archiveRun', {
        runId: this.runId,
        archiveParams: new ArchiveParams(true)
      })
        .then(() => {
          this.showSuccessNotification(
            this.$t('runInterpretation.archive.successNotification.title'),
            this.$t('runInterpretation.archive.successNotification.message', [ this.run && this.run.businessId ]),
            { root: true }
          );
          this.$emit('runDelete');
          this.$router.push({ name: 'run-list' });
        })
        .catch(error => {
          this.showErrorNotification(this.$t('error'), this.$t('runInterpretation.deleteRun.errorNotification.message'));
          console.error(`Error while archiving and deleting run: ${error}`);
        })
        .finally(() => {
          this.$refs.runDeletionModal.hideModal();
        });
    }
  }
};
</script>