<template>
  <div>
    <b-jumbotron
      class="well-interpretation-container py-4 d-flex flex-column"
      border-variant="dark"
      bg-variant="transparent">
      <transition
        name="slide-fade"
        mode="out-in">
        <div
          v-if="!isUpdatingDiagnostic"
          key="well-interpretation">
          <app-well-selection-filters
            :wells="selectedWells"
            :all-kit-versions="kitVersions"
            @filteredWellsUpdate="filteredWells = $event" />
          <div
            class="flex-grow-1 d-flex flex-column justify-content-between">
            <transition
              name="slide-fade"
              mode="out-in">
              <div
                v-if="!phases.length"
                ref="no-phase-data"
                key="no-phase-data"
                class="chart-bloc-container">
                <b-container class="d-flex align-items-center justify-content-center">
                  <p class="text-center my-auto">
                    {{ $t('wellInterpretationNoPhaseData') }}
                  </p>
                </b-container>
              </div>
              <div
                v-else-if="(isMultipleKitSelected || isMultipleReactionBufferSelected || isMultipleKitVersionSelected) && !filteredWells.length"
                ref="multi-kit-or-reaction-buffer-selected"
                key="multi-kit-or-reaction-buffer-selected"
                class="d-flex align-items-center justify-content-center chart-bloc-container">
                <b-container>
                  <p class="text-center my-auto">
                    {{ $t('wellInterpretationMultipleKitOrReactionBufferSelected') }}
                  </p>
                </b-container>
              </div>
              <div
                v-else
                ref="interpretation"
                key="interpretation">
                <div class="chart-bloc-container">
                  <app-chart
                    :charts="chartsForFilteredWells"
                    :phase="selectedPhase" />
                  <b-form-group>
                    <div class="d-flex justify-content-center">
                      <b-form-radio-group
                        v-model="selectedPhase"
                        :options="phases"
                        buttons
                        button-variant="outline-secondary" />
                    </div>
                  </b-form-group>
                </div>
              </div>
            </transition>
            <div>
              <transition
                name="fade"
                mode="out-in">
                <div v-if="selectedWells && selectedWells.length">
                  <hr>
                  <div>
                    <fieldset
                      v-for="selectedWell in selectedWells"
                      class="well-data-card">
                      <legend class="well-data-title">
                        {{ selectedWell.position }}
                      </legend>
                      <app-well-meta-data :well="selectedWell" />
                      <!-- Comments Section -->
                      <b-container
                        v-if="commentsByPosition(selectedWell.position).length"
                        class="section-container">
                        <div class="section-header">
                          <hr class="section-line">
                          <span class="section-title">{{ $t('wellInterpretationComments') }}</span>
                          <hr class="section-line">
                        </div>
                        <app-comment
                          :ref="'comment' + selectedWell.id"
                          :comments="commentsByPosition(selectedWell.position)"
                          :well-id="selectedWell.id"
                          :is-update-diagnostic="true"
                          @input="nonCommittedComment = $event"
                          @save="saveComment" />
                      </b-container>

                      <!-- Updated Result Section -->
                      <b-container
                        v-if="getInterpretedDiagnosticCommentByWellId(selectedWell.id) && getInterpretedDiagnosticCommentByWellId(selectedWell.id).length"
                        class="section-container">
                        <div class="section-header">
                          <hr class="section-line">
                          <span class="section-title">{{ $t('wellInterpretationSuggestedResult') }}</span>
                          <hr class="section-line">
                        </div>
                        <app-comment
                          :comments="getInterpretedDiagnosticCommentByWellId(selectedWell.id)"
                          :is-update-diagnostic="true"
                          @input="nonCommittedComment = $event"
                          @save="saveComment" />
                      </b-container>
                    </fieldset>
                    <b-container>
                      <app-comment
                        ref="comment"
                        :comments="[]"
                        @input="nonCommittedComment = $event"
                        @save="saveCommentForWells" />
                    </b-container>
                  </div>
                </div>
              </transition>
            </div>
            <b-container>
              <b-form-row class="mt-4">
                <b-col sm="12">
                  <div class="d-flex flex-row align-items-center justify-content-between">
                    <b-link
                      class="fa-pull-left align-middle"
                      @click="emitCloseEvent">
                      {{ $t('cancel') }}
                    </b-link>
                    <b-button
                      ref="updateDiagnosticButton"
                      variant="outline-secondary"
                      :disabled="!isDiagnosticUpdateAllowed"
                      @click="isUpdatingDiagnostic = true">
                      {{ $t('wellInterpretationUpdateDiagnosticButtonText') }}
                    </b-button>
                  </div>
                </b-col>
              </b-form-row>
            </b-container>
          </div>
        </div>
        <div
          v-else
          key="diagnosticUpdate">
          <div class="chart-bloc-container">
            <app-chart
              :charts="chartsForFilteredWells"
              :phase="selectedPhase" />
            <b-form-group>
              <div class="d-flex justify-content-center">
                <b-form-radio-group
                  v-model="selectedPhase"
                  :options="phases"
                  buttons
                  button-variant="outline-secondary" />
              </div>
            </b-form-group>
          </div>
          <app-diagnostic-update-form
            :run-id="run.id"
            :well="selectedWells[0]"
            @cancel="isUpdatingDiagnostic = false"
            @updated="showNotificationAndCloseDiagnosticUpdateForm" />
        </div>
      </transition>
    </b-jumbotron>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Chart from './Chart';
import CommentComponent from '../../comment/Comment';
import NotificationMixin from '../../../../../mixins/NotificationMixin';
import SecurityMixin from '../../../../../mixins/SecurityMixin';
import WellMetaData from './WellMetaData';
import uniqBy from 'lodash/uniqBy';
import DiagnosticUpdateForm from './DiagnosticUpdateForm';
import WellSelectionFilters from './WellSelectionFilters';
import { findKitStatusByAnalyser } from '../../../../../service/GenefoxService';

export default {
  components: {
    'app-well-selection-filters': WellSelectionFilters,
    'app-chart': Chart,
    'app-well-meta-data': WellMetaData,
    'app-comment': CommentComponent,
    'app-diagnostic-update-form': DiagnosticUpdateForm
  },
  mixins: [
    NotificationMixin,
    SecurityMixin
  ],
  props: {
    selectedWells: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      nonCommittedComment: null,
      selectedPhase: undefined,
      phases: [],
      isUpdatingDiagnostic: false,
      filteredWells: [],
      kitVersions: undefined
    };
  },
  computed: {
    ...mapState('runImport', [
      'run'
    ]),
    ...mapGetters('chart', [
      'filteredByWellIds',
      'getInterpretedDiagnosticCommentByWellId'
    ]),
    ...mapGetters('runImport', [
      'wellsByPosition',
      'commentsByPosition',
      'wells'
    ]),
    well() {
      return this.selectedWells[0];
    },
    interpretedDiagnosticCommentByWellId() {
      return this.getInterpretedDiagnosticCommentByWellId(this.well.id);
    },
    chartsForSelectedWells() {
      return this.filteredByWellIds(this.selectedWells.map(well => well.id));
    },
    chartsForFilteredWells() {
      return this.filteredByWellIds(this.filteredWells.map(well => well.id));
    },
    isMultipleKitSelected() {
      return uniqBy(this.selectedWells, 'kitVersion.kit.id').length > 1;
    },
    isMultipleKitVersionSelected() {
      return uniqBy(this.selectedWells, 'kitVersion.id').length > 1;
    },
    isMultipleReactionBufferSelected() {
      return uniqBy(this.selectedWells, 'reactionBuffer.id').length > 1;
    },
    isDiagnosticUpdateAllowed() {
      return this.selectedWells.length === 1 && this.canUpdateRun();
    }
  },
  watch: {
    well() {
      this.nonCommittedComment = null;
    },
    selectedWells(newSelectedWells, oldSelectedWells) {
      Object.keys(this.$refs)
        .filter(key => key.startsWith('comment'))
        .map(key => this.$refs[key])
        .flat()
        .forEach(c => c?.resetValueToDefault());
      if (newSelectedWells.length !== 1 || oldSelectedWells.length !== 1 || newSelectedWells[0].id !== oldSelectedWells[0].id) {
        this.isUpdatingDiagnostic = false;
      }
    }
  },
  beforeMount() {
    this.phases = this.chartsForSelectedWells.length ? this.chartsForSelectedWells[0].data.map(chartData => chartData.type).sort() : [];
    this.selectedPhase = this.phases.length ? this.phases[0] : undefined;
    const allKitVersionsByWells = Array.from(this.wells.values()).filter(well => well.kitVersion).map(well => well.kitVersion);
    this.kitVersions = uniqBy(allKitVersionsByWells, 'id').sort();
    this.kitVersions.forEach(kitVersion => {
      findKitStatusByAnalyser(kitVersion.kit.id, this.run.analyser.id)
        .then(status => {
          kitVersion.kit.status = status;
          allKitVersionsByWells.filter(kv => kv.id === kitVersion.id).forEach(kv => kv.kit.status = status);
        })
        .catch(error => console.log(error));
    });
  },
  methods: {
    ...mapActions('runImport', [
      'createWellComment',
      'createCommentForWells'
    ]),
    emitCloseEvent() {
      this.$emit('close');
    },
    saveComment() {
      if (this.nonCommittedComment) {
        this.createWellComment({ wellId: this.well.id, comment: this.nonCommittedComment })
          .then(() => {
            Object.keys(this.$refs)
              .filter(key => key.startsWith('comment'))
              .map(key => this.$refs[key])
              .flat()
              .forEach(c => c?.resetValueToDefault());
            this.refreshComment();
          })
          .catch(error => this.showErrorNotification(this.$t('errorCommentCreation'), error));
      }
    },
    saveCommentForWells() {
      if (this.nonCommittedComment) {
        this.createCommentForWells({ wellIds: this.selectedWells.map(w => w.id), comment: this.nonCommittedComment })
          .then(() => {
            Object.keys(this.$refs)
              .filter(key => key.startsWith('comment'))
              .map(key => this.$refs[key])
              .flat()
              .forEach(c => c?.resetValueToDefault());
            this.refreshComment();
            this.showSuccessNotification(this.$t('wellInterpretationNotificationTitle'), this.$t('wellInterpretationCommentAdded'));
          })
          .catch(error => this.showErrorNotification(this.$t('errorCommentCreation'), error));
      }
    },
    showNotificationAndCloseDiagnosticUpdateForm(interpretedWell) {
      let diagnosticTranslation;

      if (interpretedWell.interpretedWellDiagnostics[1].sampleType === 'POSITIVE_TEST'
          || interpretedWell.interpretedWellDiagnostics[1].sampleType === 'NEGATIVE_TEST'
          || interpretedWell.interpretedWellDiagnostics[1].sampleType === 'STANDARD') {
        diagnosticTranslation = (this.$t(`${interpretedWell.interpretedWellDiagnostics[1].sampleType}_${interpretedWell.interpretedWellDiagnostics[1].diagnosticColor.diagnostic.name}`));
      } else {
        diagnosticTranslation = this.$t(interpretedWell.interpretedWellDiagnostics[1].diagnosticColor.diagnostic.name) + (interpretedWell.interpretedWellDiagnostics[1].diagnosticColor.diagnosticLabel ? (` ${interpretedWell.interpretedWellDiagnostics[1].diagnosticColor.diagnosticLabel}`) : '');
      }

      this.showSuccessNotification(
        this.$t('diagnosticUpdateFormUpdateSuccessNotificationTitle', [ interpretedWell.position ]),
        this.$t('diagnosticUpdateFormUpdateSuccessNotificationMessage', [ diagnosticTranslation ]),
        { delay:6000 });
      this.isUpdatingDiagnostic = false;
    },
    refreshComment() {
      this.$forceUpdate();
      this.$emit('update');
    }
  }
};
</script>
<style scoped>
  .well-data-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    background-color: #f8f9fa;
  }

  .well-data-title {
    font-weight: bold;
    font-size: 16px;
    color: rgb(33, 37, 41);
  }

  .section-container {
    margin-bottom: 20px;
  }

  .section-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .section-line {
    flex-grow: 1;
    border: none;
    border-top: 1px solid #ccc;
    margin: 0 10px;
  }

  .section-title {
    font-weight: bold;
    font-size: 16px;
  }
</style>