<template>
  <div class="plate-container">
    <!-- headers -->
    <b-row class="justify-content-center">
      <!-- corner -->
      <app-plate-header
        :key="`header(0)`"
        :class="`d-flex justify-content-center align-items-center ` + wellContainerCssClass"
        @select="selectAllWells()">
        {{ $t('allPlate') }}
      </app-plate-header>
      <!-- top header (number) -->
      <app-plate-header
        v-for="num in rows[0].length"
        :key="`header(${num})`"
        :class="`d-flex justify-content-center align-items-center ` + wellContainerCssClass"
        @select="onSelectColumn(num - 1, $event)">
        {{ num }}
      </app-plate-header>
    </b-row>

    <!-- rows -->
    <b-row
      v-for="(row, y) in rows"
      class="justify-content-center">
      <!-- left header (letter) -->
      <app-plate-header
        :ref="`row(${y})`"
        :class="`d-flex justify-content-center align-items-center ` + wellContainerCssClass"
        @select="onSelectRow(y, $event)">
        {{ toLetter(y) }}
      </app-plate-header>
      <!-- wells -->
      <div
        v-for="(well, x) in row"
        :key="`column(${x},${y})`"
        :class="`d-flex justify-content-center align-items-center ` + wellContainerCssClass">
        <app-well
          :key="well.position"
          :ref="well.position"
          :selected="selectedWells.has(well.position)"
          :well="well"
          :plate-type="plateType"
          :badged="isBadged(well)"
          class="w-100 h-100"
          :analyzed="isWellDisplayedAsAnalyzed(well)"
          @select="onSelectWell(x, y, $event)" />
      </div>
    </b-row>
  </div>
</template>

<script>
import Well from './Well';
import PlateHeader from './PlateHeader';
import {mapGetters} from 'vuex';
import PlateType from '../../../../models/PlateType';

export default {
  components: {
    'app-well': Well,
    'app-plate-header': PlateHeader
  },
  props: {
    wells: {
      type: Map,
      default: () => new Map()
    },
    initialSelectionByPosition: {
      type: Array,
      default: undefined
    },
    plateType: {
      type: PlateType,
      default: () => PlateType.RECTANGULAR_96
    }
  },
  data() {
    return {
      startingLetter: 'A',
      selectedWells: new Map(),
      lastSelect: undefined
    };
  },
  mounted() {
    if (this.initialSelectionByPosition?.length) {
      this.onSelectChannel(this.initialSelectionByPosition);
    }
  },
  computed: {
    ...mapGetters('runImport', [
      'isRunInterpreted',
      'commentsByPosition',
    ]),
    ...mapGetters('chart', [
      'isWellAnalyzed',
      'getDiagnosticByWellId',
      'getInterpretedDiagnosticCommentByWellId'
    ]),
    rows() {
      return [ ...this.wells.values() ].reduce((rows, well) => {
        well.analyseWell = this.isRunInterpreted && this.isWellAnalyzed(well);
        const { x, y } = this.positionToCoordinate(well.position);
        if (!rows[y]) {
          rows[y] = [];
        }
        rows[y][x] = well;

        return rows;
      }, []);
    },
    wellContainerCssClass() {
      let cssClass;

      switch (this.plateType) {
        case PlateType.RECTANGULAR_384:
          cssClass = 'well-container-rectangular-384';
          break;
        case PlateType.RECTANGULAR_96:
        default:
          cssClass = 'well-container-rectangular-96';
          break;
      }

      return cssClass;
    }
  },
  methods: {
    toLetter(rowIndex) {
      return String.fromCharCode(this.startingLetter.charCodeAt(0) + rowIndex);
    },
    onSelectColumn(x, select) {
      this.applySelection({ x }, select.options);
    },
    onSelectRow(y, select) {
      this.applySelection({ y }, select.options);
    },
    onSelectWell(x, y, select) {
      this.applySelection({ x, y }, select.options);
    },
    onSelectChannel(wells) {
      const selection = new Map();
      this.initSelection(wells, selection, false, {});
    },
    applySelection(select, options) {
      if (options.ctrl && options.shift) {
        return;
      }
      const wells = this.selectWells(select, options);
      const selection = options.ctrl || options.shift ?
        new Map(this.selectedWells.entries()) :
        new Map();
      const allAlreadySelected = wells.every(well => this.selectedWells.has(well.position) || (this.isRunInterpreted && !well.analyseWell));
      this.initSelection(wells, selection, allAlreadySelected, options);
    },
    selectWells(select, options) {
      const selection = [];
      const start = this.lastSelect ?? { x: 0, y: 0 };
      const end = { ...select };
      if (!options.shift) {
        if (select.x !== undefined && select.y !== undefined) {
          selection.push(this.rows[select.y][select.x]);
        } else if (select.x !== undefined) {
          selection.push(...this.rows.map(row => row[select.x]));
        } else if (select.y !== undefined) {
          const row = this.rows[select.y];
          selection.push(...row);
        }

        this.lastSelect = select;

        return selection;
      }

      let rows = [];
      if (start.y <= end.y) {
        rows = this.rows.slice(start.y, end.y !== undefined ? end.y + 1 : end.y);
      } else {
        rows = this.rows.slice(end.y, start.y !== undefined ? start.y + 1 : start.y);
      }

      for (let i = 0; i < rows.length; i++) {
        let columns = [];
        if (start.x <= end.x) {
          columns = rows[i].slice(
            start.x === undefined ? 0 : start.x,
            end.x === undefined ? undefined : end.x + 1
          );
        } else {
          columns = rows[i].slice(
            end.x === undefined ? 0 : end.x,
            start.x === undefined ? undefined : start.x + 1
          );
        }

        selection.push(...columns);
      }

      return selection;
    },
    initSelection(wells, selection, allAlreadySelected, options) {
      for (const well of wells) {
        if (this.isRunInterpreted && !well.analyseWell) {
          continue;
        }
        if (!selection.has(well.position)) {
          selection.set(well.position, well);
        } else if ((!options.shift && allAlreadySelected) || (!options.ctrl && !options.shift)) {
          selection.delete(well.position);
        }
      }
      this.selectedWells = new Map([ ...selection ].sort((a, b) => a[0][0] === b[0][0] ?
        Number(a[0].split(/[A-Z]/)[1]) - Number(b[0].split(/[A-Z]/)[1]) :
        a[0][0] > b[0][0] ? 1 : -1));
      this.$emit('select', Array.from(this.selectedWells.values()));
    },
    positionToCoordinate(position) {
      return {
        x: Number.parseInt(position.substring(1)) - 1,
        y: position.charCodeAt(0) - this.startingLetter.charCodeAt(0)
      };
    },
    isWellDisplayedAsAnalyzed(well) {
      return !this.isRunInterpreted || this.isWellAnalyzed(well);
    },
    selectAllWells() {
      this.lastSelect = undefined;
      this.applySelection({ x: this.plateType.columnCount - 1, y: this.plateType.rowCount - 1 }, { shift: true });
    },
    selectWellsByDiagnostic(diagnosticColor) {
      const wells = Array.from(this.wells.values())
        .filter(well => { const diagnosticByWell = this.getDiagnosticByWellId(well.id);

          return diagnosticByWell?.color === diagnosticColor.color
						&& diagnosticByWell?.diagnostic.name === diagnosticColor.diagnostic.name
						&& diagnosticByWell?.diagnostic?.label === diagnosticColor.diagnostic?.label; });
      this.initSelection(wells, new Map(), false);
    },
    isBadged(well) {
      const comments = this.commentsByPosition(well.position);
      return !!(comments?.length || this.getInterpretedDiagnosticCommentByWellId(well.id)?.length);
    },
    selectWellsByDiagnosticAndSampleType(diagnosticColor, sampleType) {
      const wells = Array.from(this.wells.values())
        .filter(well => well.sampleType === sampleType
                                         && this.getDiagnosticByWellId(well.id)?.diagnostic.name === diagnosticColor);
      this.initSelection(wells, new Map(), false);
    }
  }
};
</script>