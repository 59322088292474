<template>
  <div>
    <b-jumbotron
      class="pb-4 pt-4"
      border-variant="dark"
      bg-variant="transparent">
      <h3>{{ $t('exportWindowTitle') }}</h3>
      
      <!-- Export Type -->
      <b-form-group
        :label="$t('formExportFormat')">
        <app-export-format-dropdown v-model="selectedExportFormat" />
      </b-form-group>
      
      <!-- CSV Parameters Form Group -->
      <b-form-group v-if="selectedExportFormat === RunExportFormat.CSV">
        <b-form-row>
          <b-col cols="6 pl-0">
            <b-form-group :label="$t('formCsvDelimiter')" class="m-0">
              <app-csv-delimiter-dropdown v-model="selectedCsvDelimiter" />
          </b-form-group>
          </b-col>
          <b-col cols="6 pl-0">
            <b-form-group :label="$t('formRemoveTrailingColumns')" class="text-center w-100 m-0">
              <b-form-checkbox v-model="showEmptyTrailingColumns"
                class="d-flex align-items-center justify-content-center">
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form-group>

      <!-- Export Data -->
      <b-form-group
        :label="$t('formExportData')">
        <app-export-data-dropdown v-model="selectedExportMode" />
        <b-form-checkbox
          v-if="isPdfExport && selectedExportMode !== 'EXPORT_DATA_WELL_NAME'"
          v-model="isExportAsPlate">
          {{ $t('exportAsAPlate') }}
        </b-form-checkbox>
      </b-form-group>

      <!-- Sort by -->
      <b-form-group
        :label="$t('formWellSortDirectionPlaceholder')">
        <b-form-checkbox
            v-model="rowDirection"
            size="lg"
            switch>
        <span>{{ rowDirection ? $t('ROW') : $t('COLUMN') }}</span>
        </b-form-checkbox>
      </b-form-group>

      <!-- Filters -->
      <b-form-group
        :label="$t('filters')">
        <app-well-selection-filters
          v-if="isAllPlateSelected"
          :wells="wellsArray"
          :all-kit-versions="kitVersionArray"
          @input="kitReactionBuffers = $event" />
      </b-form-group>
      <b-form-row class="mt-4">
        <b-container class="d-flex flex-row align-items-center justify-content-between">
          <b-link
            ref="cancelLink"
            class="fa-pull-left align-middle"
            @click="emitCloseEvent">
            {{ $t('cancel') }}
          </b-link>
          <b-button
            v-if="isExportInProgress"
            ref="exportInProgressButton"
            disabled>
            <b-spinner
              small
              type="grow" />
            <b-spinner
              small
              type="grow" />
            <b-spinner
              small
              type="grow" />
          </b-button>
          <b-button
            v-else
            ref="exportButton"
            @click="generateExportFile">
            {{ $t('exportButton') }}
          </b-button>
        </b-container>
      </b-form-row>
    </b-jumbotron>
  </div>
</template>

<script>
import ExportFormatDropdown from '@/components/common/ExportFormatDropdown';
import ExportDataDropdown from '@/components/common/ExportDataDropdown';
import WellSortDirectionDropdown from '@/components/common/WellSortDirectionDropdown';
import CsvDelimiterDropdown from '@/components/common/CsvDelimiterDropdown';
import {downloadArchiveReport, downloadReport, findKitStatusByAnalyser} from '@/service/GenefoxService';
import NotificationMixin from '@/mixins/NotificationMixin';
import {mapGetters, mapState} from 'vuex';
import RunExportFormat from '../../../../../models/RunExportFormat';
import WellSelectionFilters from '@/components/run/editor/interpretation/well/WellSelectionFilters';
import Export from '@/models/Export';
import uniqBy from 'lodash/uniqBy';
import {mapFields} from "vuex-map-fields";

export default {
  components: {
    'app-export-format-dropdown': ExportFormatDropdown,
    'app-export-data-dropdown': ExportDataDropdown,
    'app-well-sort-direction-dropdown': WellSortDirectionDropdown,
    'app-well-selection-filters': WellSelectionFilters,
    'app-csv-delimiter-dropdown': CsvDelimiterDropdown,
  },
  mixins: [ NotificationMixin ],
  props: {
    selectedWells: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      RunExportFormat: RunExportFormat,
      selectedExportFormat: RunExportFormat.PDF,
      selectedExportMode: 'EXPORT_DATA_ALL_PLATE',
      rowDirection: true,
      isExportInProgress: false,
      isExportAsPlate: false,
      kitReactionBuffers: [],
      selectedCsvDelimiter: ';',
      showEmptyTrailingColumns: true
    };
  },
  computed: {
    ...mapState('runImport', {
      run: state => state.run,
      fileName : state => state.run.fileName
    }
    ),
    ...mapGetters('runImport', [
      'wells',
      'allWells',
      'wellsByWellsPosition'
    ]),
    ...mapFields('archiveRunList', [
      'runArchive'
    ]),
    wellIdsToExport() {
      let wellsToExport = [];
      if (!this.isAllPlate()) {
        wellsToExport = this.wellsByWellsPosition(this.selectedWells);
      } else {
        wellsToExport = this.allWells;
      }

      return wellsToExport.map(well => well.id);
    },
    isAllPlateSelected() {
      return this.isAllPlate();
    },
    isPdfExport() {
      if (this.selectedExportFormat === RunExportFormat.PDF) {
        return true;
      }

      return this.isExportAsPlate = false;
    },
    wellsArray() {
      return Array.from(this.allWells.values());
    },
    kitVersionArray() {
      const allKitVersionsByWells = Array.from(this.wells.values()).filter(well => well.kitVersion).map(well => well.kitVersion);
      const kitVersions = uniqBy(allKitVersionsByWells, 'id').sort();
      kitVersions.forEach(kitVersion => {
        findKitStatusByAnalyser(kitVersion.kit.id, this.run.analyser.id)
          .then(status => {
            kitVersion.kit.status = status;
            allKitVersionsByWells.filter(kv => kv.id === kitVersion.id).forEach(kv => kv.kit.status = status);
          })
          .catch(error => console.log(error));
      });

      return uniqBy(this.wellsArray.filter(well => well.kitVersion).map(well => well.kitVersion), 'id');
    },
    getExportGroupedByName() {
      return this.selectedExportMode === 'EXPORT_DATA_WELL_NAME';
    }
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    isAllPlate() {
      return this.selectedExportMode !== 'EXPORT_DATA_SELECTION';
    },
    generateExportFile() {
      if (this.wellIdsToExport.length > 0) {
        this.isExportInProgress = true;
        let exportModel = Export.build({
          wellIds: this.wellIdsToExport,
          kitReactionBuffers: this.kitReactionBuffers,
          format: this.selectedExportFormat.name,
          isExportAsPlate: this.getExportGroupedByName ? false : this.isExportAsPlate,
          sortDirection: this.rowDirection ? 'ROW' : 'COLUMN',
          csvDelimiter: this.selectedCsvDelimiter,
          csvShowTrailing: this.showEmptyTrailingColumns,
          groupByWellName: this.getExportGroupedByName,
          fileName: this.fileName
        });
        let promise = this.runArchive ? downloadArchiveReport(this.run.id, exportModel)
            : downloadReport(this.run.id, exportModel);
        promise
            .then(() => this.emitCloseEvent())
            .catch(error => {
              this.emitCloseEvent();
              this.showErrorNotification(this.$t('errorExportGeneration'), error, { root: true });
            });
      } else {
        this.showErrorNotification(this.$t('errorExportGeneration'), this.$t('errorExportGenerationNoWellAvailable'));
      }
    }
  }
};
</script>
