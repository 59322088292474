import { getField, updateField } from 'vuex-map-fields';
import { GenefoxApi } from '../../service/http-common';
import Run from '../../models/Run';

const getDefaultState = () => ({
  runs: [],
  runCount: 0,
  runPerPage: 13,
  run: new Run(),
  currentSearch: '',
  abortController: null
});

const state = getDefaultState();

const getters = {
  getField
};

export const mutations = {
  updateField,
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  setAbortController(state, controller) {
    state.abortController = controller;
  }
};

export const actions = {
  findRuns({ commit, dispatch }, { page, search, sort, sortDirection }) {
    const controller = new AbortController();
    commit('setAbortController', controller);

    return new Promise((resolve, reject) => {
      GenefoxApi.get('/run', {
        params: { page: page - 1, size: state.runPerPage, search, sort, sortDirection },
        headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` },
        signal: controller.signal
      })
        .then(response => {
          const runs = response.data.listObjects.map(run => Run.parse(run));
          dispatch('setRunSearchResults', { currentSearch: search, runCount: response.data.numberOfObjects, runs });
          resolve(runs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  cancel() {
    if (state.abortController) {
      state.abortController.abort();
    }
  },
  findRun({ commit }, id) {
    return new Promise((resolve, reject) => {
      GenefoxApi.get(`/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
        .then(response => {
          const run = Run.create(response.data);
          commit('updateField', { path: 'run', value: run });
          resolve(run);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setRunPerPage({ commit }, runPerPage) {
    commit('updateField', { path: 'runPerPage', value: runPerPage });
  },
  reset({ commit }) {
    commit('resetState');
  },
  setRunSearchResults({ commit }, { currentSearch, runCount, runs }) {
    commit('updateField', { path: 'currentSearch', value: currentSearch });
    commit('updateField', { path: 'runCount', value: runCount });
    commit('updateField', { path: 'runs', value: runs });
  },
  clearRuns({ commit }) {
    commit('updateField', { path: 'runs', value: [] });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
