import Run from './Run';

export default class ArchivedRun extends Run {
  constructor(id, businessId, plateId, plateType, name, created, createdByName, instrumentName, analyser, kits, analyses, runImportDate, status, submittedByName, interpretationRunByName, comments, fileName, usedKitReactionBufferPairs, rejectedBy, approvedBy, laboratory, ftpSent, deletionDate) {
    super(id, businessId, plateId, plateType, name, created, createdByName, instrumentName, analyser, kits, analyses, runImportDate, status, submittedByName, interpretationRunByName, comments, fileName, usedKitReactionBufferPairs, rejectedBy, approvedBy, laboratory, ftpSent);
    this.deletionDate = deletionDate;
  }

  static build(data) {
    return new ArchivedRun(
      data.id,
      data.businessId,
      data.plateId,
      data.plateType,
      data.name,
      data.created,
      data.createdByName,
      data.instrumentName,
      data.analyser,
      data.kits,
      data.analyses,
      data.runImportDate,
      data.status,
      data.submittedByName,
      data.interpretationRunByName,
      data.comments,
      data.fileName,
      data.usedKitReactionBufferPairs,
      data.rejectedBy,
      data.approvedBy,
      data.laboratory,
      data.ftpSent,
      data.deletionDate
    );
  }

  static parse(value) {
    return value ? ArchivedRun.build({
      ...Run.parse(value),
      deletionDate: value.deletionDate
    }) : null;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      deletionDate: this.deletionDate || null
    };
  }
}
