<template>
  <app-run-list :is-archive-mode="true" />
</template>

<script>
import RunList from '../list/RunList';

export default {
  components: {
    'app-run-list': RunList
  }
};
</script>
