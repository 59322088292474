<template>
  <app-run-interpretation :is-archive-mode="true" />
</template>

<script>

import RunInterpretation from '@/components/run/editor/interpretation/RunInterpretation.vue';

export default {
  components: {
    'app-run-interpretation': RunInterpretation
  }
};
</script>
