import { getField, updateField } from 'vuex-map-fields';
import * as GenefoxService from '@/service/GenefoxService';
import ArchivedRun from '../../models/ArchivedRun';

const state = {
  searchCriteria: [],
  runArchive: undefined,
  abortController: null
};

const getters = {
  getField
};

const mutations = {
  updateField,
  setAbortController(state, controller) {
    state.abortController = controller;
  }
};

const actions = {
  findArchivedRuns({ commit, dispatch, rootState }, { page, sort, sortDirection }) {
    const controller = new AbortController();
    commit('setAbortController', controller);

    return new Promise((resolve, reject) => {
      const query = {
        searchCriteria: state.searchCriteria,
        size: rootState.runList.runPerPage,
        page: page - 1,
        sort,
        sortDirection
      };
      GenefoxService.findArchivedRuns(query, controller)
        .then(response => {
          const runs = response.data.values.map(run => ArchivedRun.parse(run));
          dispatch('runList/setRunSearchResults', { currentSearch: '', runCount: response.data.count, runs }, { root: true });
          resolve(runs);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  cancel() {
    if (state.abortController) {
      state.abortController.abort();
    }
  },
  permanentDeleteArchive({}, { runId }) {
    return GenefoxService.deleteArchivedRun(runId);
  },
  restoreArchive({}, { runId }) {
    return GenefoxService.restoreArchivedRun(runId);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
