import Vue from 'vue';
import Router from 'vue-router';
import RunImport from './components/run/import/RunImport';
import RunList from './components/list/RunList';
import ArchiveList from './components/archive/ArchiveList';
import RunInterpretation from './components/run/editor/interpretation/RunInterpretation';
import RunConfiguration from './components/run/editor/configuration/RunConfiguration';
import Configuration from './components/configuration/Configuration';
import Laboratories from '@/components/admin/laboratory/Laboratories';
import LaboratoryAdd from '@/components/admin/laboratory/LaboratoryAdd';
import Laboratory from '@/components/admin/laboratory/Laboratory';
import Admin from '@/components/admin/Admin';
import ArchiveInterpretation from '@/components/run/editor/interpretation/archive/ArchiveInterpretation.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/*&state*'
    },
    {
      path: '/run/import',
      name: 'run-import',
      component: RunImport
    },
    {
      path: '/run/:id/configuration',
      name: 'run-creation',
      component: RunConfiguration,
      props: {
        initialFormStep: 1
      }
    },
    {
      path: '/run/:id/interpretation',
      name: 'run-interpretation',
      component: RunInterpretation
    },
    {
      path: '/archive/run/:id/interpretation',
      name: 'archive-interpretation',
      component: ArchiveInterpretation
    },
    {
      path: '/',
      name: 'run-list',
      component: RunList
    },
    {
      path: '/laboratory',
      name: 'laboratory',
      component: Laboratory
    },
    {
      path: '/archive',
      name: 'archive',
      component: ArchiveList
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      children: [
        {
          path: 'configuration',
          name: 'configure',
          component: Configuration
        },
        {
          path: 'laboratories',
          name: 'laboratories',
          component: Laboratories
        },
        {
          path: 'add',
          name: 'laboratory-add',
          component: LaboratoryAdd
        }
      ]
    },
    {
      path: '/*',
      redirect: '/404'
    }
  ]
});
