import { GenefoxApi, GenefoxDownloadFile, GenefoxMultipartApi } from './http-common';
import Run from '../models/Run';
import Comment from '../models/Comment';
import Analyser from '../models/Analyser';
import Kit from '../models/Kit';
import Lot from '../models/Lot';
import PlateTemplate from '../models/PlateTemplate';
import InterpretedWell from '../models/InterpretedWell';
import Laboratory from '@/models/Laboratory';
import User from '@/models/User';
import Release from '@/models/Release';
import Phase from '@/models/Phase';
import KitToSave from '@/models/KitToSave';
import Channel from '@/models/Channel';
import Expression from '@/models/Expression';
import CombinationDiagnostic from '@/models/CombinationDiagnostic';
import DiagnosticColor from '@/models/DiagnosticColor';
import KitVersion from '@/models/KitVersion';
import PublicProperties from '@/models/PublicProperties';
import ReactionBuffer from '../models/ReactionBuffer';
import FTPConfiguration from '@/models/FTPConfiguration';

export const findRun = id => new Promise((resolve, reject) => {
  GenefoxApi.get(`/run/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const updateRun = run => new Promise((resolve, reject) => {
  GenefoxApi.put(`/run/${run.id}`, run, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const deleteRun = runId => new Promise((resolve, reject) => {
  GenefoxApi.delete(`/run/${runId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const archiveRun = (runId, archiveParams) => new Promise((resolve, reject) => {
  GenefoxApi.post(`/run/${runId}/archive`, archiveParams, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve())
    .catch(error => reject(error));
});

export const checkRunParsed = id => new Promise((resolve, reject) => {
  GenefoxApi.get(`/run/${id}/parsed`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const findRunCharts = id => new Promise((resolve, reject) => {
  GenefoxApi.get(`/run/${id}/chart`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(interpretedWell => InterpretedWell.parse(interpretedWell))))
    .catch(error => reject(error));
});

export const findAllAnalysers = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/analysers', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(analyser => Analyser.parse(analyser))))
    .catch(error => reject(error));
});

export const getConfiguredAnalyser = kitVersionId => new Promise((resolve, reject) => {
  GenefoxApi.get(`/analysers/kitVersion/${kitVersionId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(analyser => Analyser.parse(analyser))))
    .catch(error => reject(error));
});

export const findAllKits = (onlyActive, onlyValid) => new Promise((resolve, reject) => {
  GenefoxApi.get('/kit', { params: { onlyActive, onlyValid },
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(kit => Kit.parse(kit))))
    .catch(error => reject(error));
});

export const findAllKitsByAnalyserId = id => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(kit => Kit.parse(kit))))
    .catch(error => reject(error));
});

export const findAllKitsWithLotByAnalyserId = id => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${id}/lots`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(kit => Kit.parse(kit))))
    .catch(error => reject(error));
});

export const findKitStatusByAnalyser = (id, analyserId) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${id}/status/${analyserId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data))
    .catch(error => reject(error));
});

export const findExportableKit = (onlyActive, onlyValid, isKitExport) => new Promise((resolve, reject) => {
  GenefoxApi.get('/kit/exportable', { params: { onlyActive, onlyValid, isKitExport }, headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data))
    .catch(error => reject(error));
});

export const updateStatus = (id, status) => new Promise((resolve, reject) => {
  GenefoxApi.put(`run/${id}/status?status=${status}`, null, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});


export const getKitCombinations = (kitVersionId, rbId, analyserId, channelId) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/version/${kitVersionId}/analyser/${analyserId}/reaction-buffer/${rbId}/channel/${channelId}/combinations`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(combinationDiagnostic => CombinationDiagnostic.parse(combinationDiagnostic))))
    .catch(error => reject(error));
});

export const getKitCombinationsLastVersion = (kitId, rbId, analyserId, channelId) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${kitId}/last-version/analyser/${analyserId}/reaction-buffer/${rbId}/channel/${channelId}/combinations`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(combinationDiagnostic => CombinationDiagnostic.parse(combinationDiagnostic))))
    .catch(error => reject(error));
});

export const getRbChannelDiagnosticColors = (kitId, rbId, analyserId, channelName) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${kitId}/analyser/${analyserId}/reaction-buffer/${rbId}/channel/${channelName}/diagnostic-colors`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(diagnosticColor => DiagnosticColor.parse(diagnosticColor))))
    .catch(error => reject(error));
});

export const getRbAnalyserChannelDiagnosticColors = (kitId, rbIds, analyserId, channelNames) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${kitId}/analyser/${analyserId}/reaction-buffers/channels/diagnostic-colors`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }, params: { rbIds, channelNames } })
    .then(response =>  resolve(response.data.map(diagnosticColor => DiagnosticColor.parse(diagnosticColor))))
    .catch(error => reject(error));
});

export const getTestDiagnosticColors = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/kit/test-diagnostic-colors', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(diagnosticColor => DiagnosticColor.parse(diagnosticColor))))
    .catch(error => reject(error));
});

export const getDiagnosticColorsByKit = kitId => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${kitId}/diagnostic-colors`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(diagnosticColor => DiagnosticColor.parse(diagnosticColor))))
    .catch(error => reject(error));
});

export const getKitAvailableExpressions = (kitId, operandsByPhase) => new Promise((resolve, reject) => {
  if (operandsByPhase.length === 0) {
    return [];
  }
  const operands = [];
  operandsByPhase.forEach(obp => operands.push(obp.join(','))); // to get an url like localhost?operandsByPhase=A,B&operandsByPhase=C,D where A and B are phase 1 zones
  if (operandsByPhase.length === 1) {
    operands.push([ null ]);
  }
  GenefoxApi.get('/kit/expressions', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }, params: { 'operandsByPhase': operands }  })
    .then(response => resolve(response.data.map(expression => Expression.parse(expression))))
    .catch(error => reject(error));
});

export const findAllLots = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/lots', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(lot => Lot.parse(lot))))
    .catch(error => reject(error));
});

export const findKitVersionsByAnalyser = (id, analyserId) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${id}/analyser/${analyserId}/versions`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(kv => KitVersion.parse(kv))))
    .catch(error => reject(error));
});

export const createKit = kitToSave => new Promise((resolve, reject) => {
  GenefoxApi.post('/kit', kitToSave, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(KitToSave.parse(response.data)))
    .catch(error => reject(error));
});

export const updateKit = (id, kitToSave) => new Promise((resolve, reject) => {
  GenefoxApi.put(`/kit/${id}`, kitToSave, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(KitToSave.parse(response.data)))
    .catch(error => reject(error));
});

export const saveLot = (versionId, lot) => new Promise((resolve, reject) => {
  GenefoxApi.post(`/lots/version/${versionId}`, lot, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Lot.parse(response.data)))
    .catch(error => reject(error));
});

export const findKitChannels = (kitId, rbId) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${kitId}/reaction-buffer/${rbId}/channels`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(channel => Channel.parse(channel))))
    .catch(error => reject(error));
});

export const findAllSampleTypes = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/well/sampleTypes',{ headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const updateDiagnostic = labels => new Promise((resolve, reject) => {
  GenefoxApi.put('/diagnostics/kit', labels, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data))
    .catch(error => reject(error));
});

export const getDiagnosticLabels = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/diagnostics/kit/labels', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data))
    .catch(error => reject(error));
});

export const getKitVersions = (kitId, onlyActive, onlyValid) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${kitId}/versions`, { params: { onlyActive, onlyValid }, headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(kitVersions => KitVersion.parse(kitVersions))))
    .catch(error => reject(error));
});

export const getKitVersionsLinkedToLotDisabled = kitId => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${kitId}/versions/lot/disabled`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(kitVersions => KitVersion.parse(kitVersions))))
    .catch(error => reject(error));
});

export const findExportableKitVersions = (kitId, onlyActive, onlyValid, isKitExport) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/${kitId}/versions/exportable`, { params: { onlyActive, onlyValid, isKitExport }, headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(kitVersions => KitVersion.parse(kitVersions))))
    .catch(error => reject(error));
});

export const createRun = (files, analyser) => new Promise((resolve, reject) => {
  const formData = new FormData();
  files.forEach(file => formData.append('files', file));
  formData.append('analyser', JSON.stringify(analyser));

  GenefoxMultipartApi.post('/run/createRun', formData, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const saveAcquisitions = (runId, files, analyser, kitIds) => new Promise((resolve, reject) => {
  const formData = new FormData();
  files.forEach(file => formData.append('files', file));
  formData.append('analyser', JSON.stringify(analyser));
  formData.append('kitIds', kitIds);

  GenefoxMultipartApi.post(`/run/acquisitions/${runId}`, formData, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error));
});

export const createCommentForWells = (runId, wellIds, comment) => new Promise((resolve, reject) => {
  GenefoxApi.post(`/run/${runId}/wells/comment`, comment, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }, params: { wellIds } })
    .then(response => resolve(Object.entries(response.data).map(entry => {
      const comments = entry[1].map(comment => Comment.parse(comment));

      return { wellId: Number.parseInt(entry[0]), comments };
    })))
    .catch(error => reject(error));
});

export const createWellComment = (runId, wellId, comment) => new Promise((resolve, reject) => {
  GenefoxApi.post(`/run/${runId}/well/${wellId}/comment`, comment, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(comment => Comment.parse(comment))))
    .catch(error => reject(error));
});

export const createRunComment = (runId, comment) => new Promise((resolve, reject) => {
  GenefoxApi.post(`/run/${runId}/comment`, comment, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(comment => Comment.parse(comment))))
    .catch(error => reject(error));
});

export const reindexData = () => new Promise((resolve, reject) => {
  GenefoxApi.post('/run/reindex-elasticsearch', null, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(_ => resolve())
    .catch(error => reject(error));
});

export const downloadReport = (runId, exportModel) => new Promise((resolve, reject) => {
  GenefoxApi.post(`/run/export/${runId}`, exportModel, {
    responseType: 'arraybuffer',
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => {
      const blob = new Blob([ response.data ], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${exportModel.fileName}.${exportModel.groupByWellName && response.headers['content-type'].split('/')[1] === 'zip'
        ? 'zip' : exportModel.format.toLowerCase()}`;
      link.click();
      resolve();
    })
    .catch(error => reject(error));
});

export const downloadArchiveReport = (runId, exportModel) => new Promise((resolve, reject) => {
  GenefoxApi.post(`/run/archive/${runId}/export`, exportModel, {
    responseType: 'arraybuffer',
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => {
      const blob = new Blob([ response.data ], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${exportModel.fileName}.${exportModel.groupByWellName && response.headers['content-type'].split('/')[1] === 'zip'
        ? 'zip' : exportModel.format.toLowerCase()}`;
      link.click();
      resolve();
    })
    .catch(error => reject(error));
});

export const updateWellsDiagnostic = (runId, wellIds, diagnosticColor) => new Promise((resolve, reject) => {
  if (!wellIds || !wellIds.length) reject('Diagnostic not updated: no well id provided');
  if (!diagnosticColor) reject('Diagnostic not updated: no diagnostic provided');

  const data = {
    wellIds,
    diagnosticColor
  };
  GenefoxApi.patch(`/run/${runId}/interpreted-wells`, data, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(interpretedWell => InterpretedWell.parse(interpretedWell))))
    .catch(error => reject(error));
});

export const findAllWellsTemplate = kitIds => new Promise((resolve, reject) => {
  GenefoxApi.get('/template-well', {
    params: { kitIds },
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => resolve(response.data.map(template => PlateTemplate.parse(template))))
    .catch(error => reject(error));
});

export const createPlateTemplate = plateTemplate => new Promise((resolve, reject) => {
  GenefoxApi.post('/template-well', plateTemplate, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(PlateTemplate.parse(response.data)))
    .catch(error => reject(error));
});

export const updatePlateTemplate = plateTemplate => new Promise((resolve, reject) => {
  GenefoxApi.put(`/template-well/${plateTemplate.id}`, plateTemplate, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(PlateTemplate.parse(response.data)))
    .catch(error => reject(error));
});

export const deletePlateTemplate = plateTemplate => new Promise((resolve, reject) => {
  GenefoxApi.delete(`/template-well/${plateTemplate.id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(PlateTemplate.parse(response.data)))
    .catch(error => reject(error));
});

export const createLaboratories = laboratories => new Promise((resolve, reject) => {
  GenefoxApi.post('/laboratory', laboratories, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(laboratory => Laboratory.parse(laboratory))))
    .catch(error => reject(error));
});

export const updateLaboratory = (id, laboratory) => new Promise((resolve, reject) => {
  GenefoxApi.put(`/laboratory/${id}`, laboratory, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Laboratory.parse(response.data)))
    .catch(error => reject(error));
});

export const deleteLaboratory = id => new Promise((resolve, reject) => {
  GenefoxApi.delete(`/laboratory/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const createLaboratoryUser = (keycloakGroupId, user) => new Promise((resolve, reject) => {
  GenefoxApi.post(`user/laboratory/${keycloakGroupId}`, user, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(User.parse(response.data)))
    .catch(error => reject(error));
});

export const updateLaboratoryUser = (keycloakGroupId, id, user) => new Promise((resolve, reject) => {
  GenefoxApi.put(`user/${id}/laboratory/${keycloakGroupId}`, user, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(User.parse(response.data)))
    .catch(error => reject(error));
});

export const deleteLaboratoryUser = (keycloakGroupId, id) => new Promise((resolve, reject) => {
  GenefoxApi.delete(`user/${id}/laboratory/${keycloakGroupId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const getLastRelease = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/release/last', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(Release.parse(response.data)))
    .catch(error => reject(error));
});

export const getLastReleaseIfNotSeen = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/release/last-if-not-seen', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(Release.parse(response.data)))
    .catch(error => reject(error));
});

export const updateSeenRelease = release => new Promise((resolve, reject) => {
  GenefoxApi.put(`/release/${release.version}/update-seen`, release, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Release.parse(response.data)))
    .catch(error => reject(error));
});

export const findAllPhasesByReactionBufferAndAnalyserAndChannel = (kitVersionId, reactionBufferId, analyserId, channelName) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/phase/kit-version/${kitVersionId}/rb/${reactionBufferId}/analyser/${analyserId}/channel/${channelName}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(phase => Phase.parse(phase))))
    .catch(error => reject(error));
});

export const findAllPhasesByReactionBufferAndAnalyserAndChannelLastVersion = (kitId, reactionBufferId, analyserId, channelName) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/phase/last-version/kit/${kitId}/rb/${reactionBufferId}/analyser/${analyserId}/channel/${channelName}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response =>  resolve(response.data.map(phase => Phase.parse(phase))))
    .catch(error => reject(error));
});

export const downloadFileRuns = id => new Promise((resolve, reject) => {
  GenefoxDownloadFile.get(`/run/${id}/fileruns`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response))
    .catch(error => reject(error));
});

export const getPublicProperties = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/public-properties', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(PublicProperties.parse(response.data)))
    .catch(error => reject(error));
});

export const findAllRbAdmin = (kitVersionId, analyserId) => new Promise((resolve, reject) => {
  GenefoxApi.get(`/kit/version/${kitVersionId}/analyser/${analyserId}/reaction-buffers/configuration`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(reactionBuffer => ReactionBuffer.parse(reactionBuffer))))
    .catch(error => reject(error));
});

export const createFTP = ftpConfiguration => new Promise((resolve, reject) => {
  GenefoxApi.post('/ftp-connection', ftpConfiguration, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(FTPConfiguration.parse(response.data)))
    .catch(error => reject(error));
});

export const updateFTP = ftpConfiguration => new Promise((resolve, reject) => {
  GenefoxApi.put('/ftp-connection', ftpConfiguration, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(FTPConfiguration.parse(response.data)))
    .catch(error => reject(error));
});

export const deleteFTP = id => new Promise((resolve, reject) => {
  GenefoxApi.delete(`/ftp-connection/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const getFTP = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/ftp-connection', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(FTPConfiguration.parse(response.data)))
    .catch(error => reject(error));
});

export const isConfigurationValidAndSendEnable = () => new Promise((resolve, reject) => {
  GenefoxApi.get('/ftp-connection/configuration/valid', { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const testFTP = ftpConfiguration => new Promise((resolve, reject) => {
  GenefoxApi.post('/ftp/test', ftpConfiguration, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(FTPConfiguration.parse(response.data)))
    .catch(error => reject(error));
});

export const sendFileFtp = runId => new Promise((resolve, reject) => {
  GenefoxApi.post(`/ftp/send/run/${runId}`, null , { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(() => resolve())
    .catch(error => reject(error));
});

export const submitResults = id => new Promise((resolve, reject) =>
  GenefoxApi.put(`run/${id}/submit`, null, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(Run.parse(response.data)))
    .catch(error => reject(error))
);

export const findLotsByVersion = (versionId, pageObject, search) => new Promise((resolve, reject) =>
  GenefoxApi.get(`lots/version/${versionId}/search`, {
    params: { page: pageObject.index, size: pageObject.size, search, sort: pageObject.sort },
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` }
  })
    .then(response => resolve(response.data))
    .catch(error => reject(error))
);

export const getLotsValidByVersionId = versionId => new Promise((resolve, reject) => {
  GenefoxApi.get(`/lots/version/${versionId}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data.map(lot => Lot.parse(lot))))
    .catch(error => reject(error));
});

export const exportKitVersion = (kitId, kitVersion) => new Promise((resolve, reject) =>
  GenefoxApi.get(`export/kit/${kitId}/version/${kitVersion.id}`, {
    responseType: 'arraybuffer',
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => {
      const blob = new Blob([ response.data ], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = response.headers['content-disposition'].match(/export.*\.json/g);
      link.click();
      resolve();
    })
    .catch(error => reject(error))
);

export const importKitVersion = (file, versionName) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('file', file);
  if (versionName) {
    formData.append('versionName', versionName);
  }

  GenefoxApi.post('import/kit/version', formData, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const exportLots = (kitVersion, lotIds) => new Promise((resolve, reject) =>
  GenefoxApi.get(`export/version/${kitVersion.id}/lot`, {
    params: { lotIds },
    responseType: 'arraybuffer',
    headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => {
      const blob = new Blob([ response.data ], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = response.headers['content-disposition'].match(/export.*\.json/g);
      link.click();
      resolve();
    })
    .catch(error => reject(error))
);

export const importLot = (file, versionName) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('file', file);
  if (versionName) {
    formData.append('versionName', versionName);
  }

  GenefoxApi.post('import/lot', formData, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const findArchivedRuns = (query, controller) => {
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('vue-token')}`
  };

  return GenefoxApi.post('/archive/runs/search', query, { headers, signal: controller.signal });
};

export const getArchivedRun = id => new Promise((resolve, reject) => {
  GenefoxApi.get(`/run/${id}/archive`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('vue-token')}` } })
    .then(response => {
      const runArchive = response.data;
      runArchive.run = Run.parse(runArchive.run);
      runArchive.interpretedWells = runArchive.interpretedWells.map(interpretedWell => InterpretedWell.parse(interpretedWell));

      return resolve(response.data);
    })
    .catch(error => reject(error));
});

export const deleteArchivedRun = runId => {
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('vue-token')}`
  };

  return GenefoxApi.delete(`/archive/runs/${runId}`, { headers });
};

export const restoreArchivedRun = runId => {
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('vue-token')}`
  };

  return GenefoxApi.post(`/archive/restore/runs/${runId}`, null, { headers });
};
