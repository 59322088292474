import {getField, updateField} from 'vuex-map-fields';
import * as GenefoxService from '@/service/GenefoxService';

const state = {
  
};

const getters = {
  getField,
};

const mutations = {
  updateField
};

const actions = {
  archiveRun(context, { runId, archiveParams }) {
    return GenefoxService.archiveRun(runId, archiveParams);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
